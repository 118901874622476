import React from 'react';
import { Container } from 'reactstrap';

function Custom404() {
  return (
    <Container className="mt-4">
      <h1 className="text-center">De pagina die je zocht werd niet gevonden</h1>
    </Container>
  );
}

export default Custom404;
